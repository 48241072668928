.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #fdfdfd;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(7, 7, 7);
}

.App-link {
  color: #108AA5;
}

.general_div{
  text-align: -webkit-center;
  margin-top: 0%;
  margin-bottom: 80px;
}

.pointer{
  cursor: pointer;
}

.modal-dialog {
  margin-top: 8.5% !important
}

.modal.top{
  overflow: scroll !important;
}

.modal-title{
  width: 100% !important;
}

/* Button Styles */

.btn-primary {
  background-color: #66CCE4 !important; 
  border-color: #66CCE4 !important;
  color: white !important;
}

.btn-primary:hover, .btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 2px 2px rgba(0,0,0,.05), 0 2px 2px #5dbcd1 !important;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 3px #5dbcd1, 0 3px 3px #5dbcd1, 0 2px 3px rgba(0,0,0,.1);
}

.btn-badge {
  background-color: #e46458 !important; 
  border-color:#e46458 !important;
  color: white !important;
}

.btn-badge:hover, .btn-badge.focus, .btn-badge:focus {
  box-shadow: 0 5px 15px rgba(0,0,0,.05), 0 4px 10px #c06f6f !important;
}

.btn-badge.focus, .btn-badge:focus {
  box-shadow: 0 0 0 3px #c06f6f, 0 3px 15px #c06f6f, 0 2px 5px rgba(0,0,0,.1);
}


.btn-badge-unwanted {
  background-color: #5ec786 !important; 
  border-color:#5ec786 !important;
  color: white !important;
}

.btn-badge-unwanted:hover, .btn-badge.focus, .btn-badge:focus {
  box-shadow: 0 5px 15px rgba(0,0,0,.05), 0 4px 10px #4d9e6c !important;
}

.btn-badge-unwanted.focus, .btn-badge:focus {
  box-shadow: 0 0 0 3px #4d9e6c, 0 3px 15px #4d9e6c, 0 2px 5px rgba(0,0,0,.1);
}

.btn-user {
  background-color: #66CCE4 !important;
  border-color: #66CCE4 !important;
  color: #5c5c5c;
}

.btn-user:hover {
  background-color: #66CCE4 !important;
  border-color: #66CCE4 !important;
  color: #5c5c5c;
}

.btn-user:focus {
  background-color: #66CCE4 !important;
  border-color: #66CCE4 !important;
  color: #727272 !important;
}

.btn-general{
  background-color: #474747 !important;
  border-color: #474747 !important;
}

.btn-general:hover{
  background-color: #474747 !important;
  border-color: #474747 !important;
}

.btn-general:focus{
  background-color: #333333 !important;
  border-color: #333333 !important;
}

.btn-general:hover, .btn-general.focus, .btn-general:focus {
  box-shadow: 0 2px 2px rgba(0,0,0,.05), 0 2px 2px #333333 !important;
}

.btn-general.focus, .btn-general:focus {
  box-shadow: 0 0 0 3px #333333, 0 3px 3px #333333, 0 2px 3px rgba(0,0,0,.1);
}

.btn-inverted {
  background-color: #FFFFFF !important;
  color: #108aa5 !important;
  border-color: #66CCE4 !important;
  color: #5c5c5c;
}

.btn-inverted:hover {
  background-color: #FFFFFF !important;
  color: #108aa5 !important;
  border-color: #66CCE4 !important;
  color: #5c5c5c;
}

.btn-inverted:focus {
  background-color: #FFFFFF !important;
  color: #108aa5 !important;
  border-color: #66CCE4 !important;
  color: #727272 !important;
}

.btn-delete {
  background-color: #FFFFFF !important;
  color: #b70f329c !important;
  border-color: #b70f329c!important;
  color: #5c5c5c;
}

.btn-delete:hover {
  background-color: #FFFFFF !important;
  color: #b70f329c !important;
  border-color: #850c249c !important;
  color: #5c5c5c;
}

.btn-delete:focus {
  background-color: #FFFFFF !important;
  color: #b70f329c !important;
  border-color: #850c249c !important;
  color: #727272 !important;
}

.btn-add {
  background-color: #FFFFFF !important;
  color: #20913cbe !important;
  border-color: #20913cbe!important;
  color: #5c5c5c;
}

.btn-add:hover {
  background-color: #FFFFFF !important;
  color: #20913cbe !important;
  border-color: #19722fbe !important;
  color: #5c5c5c;
}

.btn-add:focus {
  background-color: #FFFFFF !important;
  color: #20913cbe !important;
  border-color: #19722fbe !important;
  color: #727272 !important;
}

  /* .btn-approve.ant-btn-primary{
  background-color: #1f8d04bd;
  border-color: #1f8d04bd !important;
}

.btn-approve.ant-btn-primary:hover{
  background-color: #1f8d04bd;
  border-color: #1f8d04bd !important;
}

.btn-email-sent{
  background-color: #ffb400;
  border-color: #ffb400 !important;
}

.btn-email-sent:hover, .btn-email-sent:active, .btn-email-sent:focus{
  background-color: #df9d05;
  border-color: #df9d05 !important;
  color: #000
}
  */
/* .btn-refuse.ant-btn-danger{
  background-color: #1f8d04bd;
  border-color: #1f8d04bd !important;
}

.btn-refuse.ant-btn-danger:hover{
  background-color: #1f8d04bd;
  border-color: #1f8d04bd !important;
} */


/* List Styles */

.list:hover {
  background-color: #f0f0f0;
}

.list {
  cursor: pointer;
}

.list-group-item{
  margin-bottom: 0;
}

/* Steps Styles */
.steps-content {
  margin-top: 16px;
  margin-left: 2%;
  margin-right: 2%;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 400px;
  padding-top: 10px;
}

.ant-steps {
  padding: 2%;
}

.steps-action {
  margin-top: 15px;
  margin-left: 2%;
  margin-right: 2%;
}


/* Modal Styles */
.modal.top{
  overflow: scroll !important;
}

.modal-title{
  width: 100%;
}

.modal-dialog {
  margin-top: 11%
}



/* Sidebar Styles */
.sidebar-items {
  height: 60px !important;
  padding-left: 28px !important;
  padding-top: 8px !important;
}

/* GeneralDashboard Styles */

.shards-card-header {
  background-color: #66CCE4 !important;
  font-weight: bolder !important;
  font-size: 16px !important;
  color: #FFFFFF !important;
  border-bottom: solid !important;
  border-bottom-width: thin !important;
  border-bottom-color: #5dbcd1 !important;
}

/* Wedding Details Styles */

.detail-sider {
  background-color: #FFFFFF !important;
  border-left: solid !important;
  border-left-width: thin !important;
  border-left-color: #ececec !important;
}

.inline-col{
  display: inline-flex !important;
}

.p-title-label {
  color: #333333 !important;
  font-size: 15px !important;
  font-weight: bold !important;
  text-align: left !important;
  margin-bottom: 2px !important;
}

.p-label {
  color: #000000 !important;
  font-size: 13px !important;
  text-align: left !important;
  margin-bottom: 0px !important;
  margin-left: 9px !important;
}

.p-mini-label {
  color: #000000 !important;
  font-size: 11px !important;
  text-align: center !important;
  flex: auto !important;
  margin-bottom: 0px !important;
}

.p-title-label-lg{
  color: #333333 !important;
  font-size: 17px !important;
  font-weight: bold !important;
  text-align: left !important;
  margin-bottom: 2px !important;
}

.p-label-reminders {
  color: #000000 !important;
  font-size: 14px !important;
  text-align: left !important;
  margin-bottom: 6px !important;
  margin-left: 9px !important;
}

.btn-badge-styles {
  margin-left: 93% !important;
  text-align: center !important;
  font-size: 10px !important;
  font-weight: bold !important;
}

.unwanted-supplier-card-styles{
  min-width: 180px;
  min-height: 70px;
  max-width: 180px;
  max-height: 70px;
  margin-top: -6%;
  margin-bottom: 7%;
}

/* CARD WANTED AND OPENED */
.supplier-card-styles{
  min-width: 180px;
  min-height: 70px;
  max-width: 180px;
  max-height: 70px;
  margin-top: -6%;
  margin-bottom: 7%; 
}

.supplier-card-styles:hover, .btn-badge.focus, .btn-badge:focus {
  box-shadow: 0 5px 15px rgba(0,0,0,.05), 0 4px 10px #cacaca !important;
}

.supplier-card-styles.focus, .btn-badge:focus {
  box-shadow: 0 0 0 3px #cacaca, 0 3px 15px #cacaca, 0 2px 5px rgba(0,0,0,.1);
}

.supplier-card-content-styles{
  margin-top: 4%;
  text-align: -webkit-center;
  font-size: 13px;
}


/* CARDS WANTED AND CLOSED */
.supplier-card-styles-closed{
  min-width: 180px;
  min-height: 70px;
  max-width: 180px;
  max-height: 70px;
  margin-top: -6%;
  margin-bottom: 7%;
  background-color:#bbe4ca2e !important; 
}

.supplier-card-styles-closed:hover, .btn-badge.focus, .btn-badge:focus {
  box-shadow: 0 5px 15px rgba(0,0,0,.05), 0 4px 10px #cacaca !important;
}

.supplier-card-styles-closed.focus, .btn-badge:focus {
  box-shadow: 0 0 0 3px #cacaca, 0 3px 15px #cacaca, 0 2px 5px rgba(0,0,0,.1);
}


.supplier-card-content-styles-closed{
  margin-top: 4%;
  text-align: -webkit-center;
  font-size: 13px;
}

.card-wedding-details{
  width: 95%;
  text-align: center;
}

.card-wedding-details-header{
  background-color: #f1f1f1 !important;
}

.card-wedding-details-body{
  padding-top: 3,5%;
  padding-left: 4%;
}

.card-info{
  width: 35%;
  text-align: left;
}

.card-info-header{
  background-color: #f1f1f1 !important;
  text-align: center;
}

.card-info-body{
  padding-top: 3,5%;
  padding-left: 4%;
}


/* Syslog Styles */
.log-list{
  border-style: solid ;
  border-right: 0px !important;
  border-left: 0px !important;
  border-top-width: 0px !important;
  border-bottom-width: 2px !important;
  border-bottom-color: #cecece !important;
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}

.log-list-header{
  background-color: #f1f1f1 !important;
  border-style: solid ;
  border-right: 0px !important;
  border-left: 0px !important;
  border-top-width: 1px !important;
  border-bottom-width: 2px !important;
  border-bottom-color: #cecece !important;
  /* border-top-left-radius: 0rem !important; */
  border-bottom-left-radius: 0rem !important;
  /* border-top-right-radius: 0rem !important; */
  border-bottom-right-radius: 0rem !important;
}

.canvasjs-chart-credit{
  color: #FFFFFF !important;
  cursor: unset;

}

.canvasjs-chart-canvas{
  border-bottom-left-radius: 120px;
}

.link-login{
  color: #000000 !important;
}


/* CSS BY ELEMENT ID */

#file-upload[type=file]::-webkit-file-upload-button {
  border: 1px solid #66CCE4;
  background: #FFFFFF;
  color: #66CCE4;
  border-bottom-right-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
  border-top-left-radius: 0.2rem !important;
  cursor: pointer !important;
  margin-right: 0px;
  outline: none;
}

#file-upload:hover{
  border: 0px solid #66CCE4;
  background: #FFFFFF;
  border-bottom-right-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
  border-top-left-radius: 0.2rem !important;
  cursor: pointer !important;
  outline: none;
}

#file-upload:active{
  border: 0px solid #66CCE4;
  background: #FFFFFF;
  border-bottom-right-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
  border-top-left-radius: 0.2rem !important;
  cursor: pointer !important;
  outline: none;
}

#file-upload:focus{
  border: 0px solid #66CCE4;
  background: #FFFFFF;
  border-bottom-right-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
  border-top-left-radius: 0.2rem !important;
  cursor: pointer !important;
  outline: none;
}


a {
  color: #5a6169 !important;
}


#text-area {
  color: #495057;
  background-color: #fff;
  min-height: 80px;
  font-size: 14px;
}

#text-area:focus {
  color: #495057;
  background-color: #fff;
  min-height: 80px;
  font-size: 14px;
  border-color: #19722fbe !important;
  box-shadow: 0 0.313rem 0.719rem rgba(0,123,255,.1), 0 0.156rem 0.125rem rgba(0,0,0,.06);
}